import { Link } from 'gatsby';
import * as React from 'react';
import Layout from '../Layout';
import Markdown from 'react-markdown';
import { BlogProps } from '../../utilities/types';
import leftCaret from '../../assets/images/left-caret.svg';
import { useStrapiStore } from '../../store/useStrapiStore';
import { extractHeadings, formatDate } from '../../utilities';
import { ApiResponseLayout, BlogPosts, BreadCrumb } from '..';
import templateImage from '../../assets/images/blog-slide-1.svg';
import { socialPlatforms } from '../../utilities/data';

interface PageContext {
  pageContext: BlogProps & {
    nextArticle: BlogProps;
  };
}

const BlogDetails = ({ pageContext }: PageContext) => {
  if (!pageContext || !pageContext.slug) return null;

  const { blogData, blogState } = useStrapiStore((state) => ({
    blogData: state.blogData,
    blogState: state.blogItemsState,
  }));

  const headings = extractHeadings(pageContext.blog_content);

  if (blogState === 'loading')
    return <ApiResponseLayout apiResponse="loading" />;

  if (!pageContext || !blogData || blogState === 'error')
    return <ApiResponseLayout apiResponse="error" />;

  const handleShareLink = (url: string) => {
    console.log(url);
    const shareURL = url
      .replace('{url}', encodeURIComponent(window.location.href))
      .replace('{text}', encodeURIComponent(pageContext.blog_title));
    window.open(shareURL, '_blank', 'width=600,height=400');
  };

  return (
    <Layout
      title="Irorun | Your easy access to urgent loans"
      description="Need cash urgently? Get up to N50,000 quickly from Irorun to meet that urgent need, with low interest rates and no hassle."
    >
      <div className="blog blog__details">
        <header className="blog__details--header">
          <Link to="/blog" className="back">
            <img src={leftCaret} alt="Back to blog" />
            Back to Blog{' '}
          </Link>
          <BreadCrumb
            title={pageContext.blog_title}
            category={pageContext.blog_category}
          />
          <p className="blog__details--category">
            {pageContext.blog_category ?? 'General'}
          </p>
          <h2>{pageContext.blog_title}</h2>
          <div className="content__date--author">
            <p>
              {formatDate(
                pageContext.blog_posting_date,
                pageContext.publishedAt
              )}
            </p>
            <span
              style={{
                width: '6px',
                height: '6px',
                display: 'inline-block',
                borderRadius: '50%',
                backgroundColor: '#cfcfcf',
                cursor: 'pointer',
              }}
            ></span>
            <p>{pageContext.blog_author}</p>
          </div>
        </header>
        <section className="blog__details--content">
          <img
            src={pageContext.blog_image_url ?? templateImage}
            alt={`${pageContext.blog_title}`}
          />
          <div className="blog__details--text">
            <nav className="blog__details--navigation">
              <p>In this post</p>
              <ul>
                {headings.map((heading, index) => (
                  <Link to={`#${heading.slug}`}>
                    <li key={index}>{heading.title}</li>
                  </Link>
                ))}
              </ul>
            </nav>
            <div className="blog__details--markdown">
              <Markdown
                components={{
                  h2: ({ ...props }: any) => {
                    const title = props.children;
                    const heading = headings.find((h) => h.title === title);
                    return heading ? (
                      <h2
                        id={heading.title
                          .toLowerCase()
                          .replace(/\s+/g, '-')
                          .replace(/[^a-z0-9-]/g, '')}
                        {...props}
                      />
                    ) : (
                      <h2 {...props} />
                    );
                  },
                }}
              >
                {pageContext.blog_content}
              </Markdown>
            </div>
            <div className="social-media">
              {socialPlatforms.map((platform) => (
                <img
                  src={platform.iconPath}
                  alt={`Share on ${platform.name}`}
                  onClick={() => {
                    handleShareLink(platform.shareUrl);
                  }}
                />
              ))}
            </div>
          </div>
        </section>
      </div>
      <div className="blog__article--read-next  blog__article blog__container ">
        <h2>Read Next</h2>
        <div className="blog__article--article container ">
          <BlogPosts
            key={pageContext.nextArticle.slug}
            slug={pageContext.nextArticle.slug}
            date={
              pageContext.nextArticle.blog_posting_date ||
              formatDate(
                pageContext.nextArticle.blog_posting_date,
                pageContext.nextArticle.publishedAt
              )
            }
            title={pageContext.nextArticle.blog_title}
            author={pageContext.nextArticle.blog_author}
            image={pageContext.nextArticle.blog_image_url}
            excerpt={pageContext.nextArticle.blog_excerpt}
          />
        </div>
      </div>
    </Layout>
  );
};

export default BlogDetails;
